import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import LeadStreamer from '../../components/primary/LeadStreamer'
import CallToAction from '../../components/primary/CallToAction'
import Newsletter from '../../components/Newsletter'

import { isEven } from '../../utils/num'
import LeadSpot from '../../components/solutions/LeadSpot'

const ImageCapturePage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query ImageCapturePageQuery {
      markdownRemark(frontmatter: { path: { eq: "/image-capture" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          services {
            title
            text
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          benefitsYou {
            blurbsOne {
              title
              text
              icon
            }
            blurbsTwo {
              title
              text
            }
          }
          pricing {
            savings {
              monthly
            }
            cards {
              type
              user
              monthly
              annually
              has
            }
          }
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--manufacturers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="image-capture"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        text={pageData.lead.streamer.text}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              <div className="row">
                <div className="col-12 mb-5">
                  <h2 className="mb-4">Every shot you could want</h2>
                  <div className="c-postStreamer__text">
                    <p>
                      We cover all pack and case shots, and will take front,
                      left and right angled shots as part of the Image Capture
                      service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4 mb-5 d-lg-none text-center">
                  <img
                    src="/images/solutions/image-capture/Crisp-Packet-Front-Up-Mob.jpg"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Crisp-Packet-View-2.jpg"
                    srcSet="/images/solutions/image-capture/Crisp-Packet-View-2.jpg, /images/solutions/image-capture/Crisp-Packet-View-2@1.5x.jpg 1.5x, /images/solutions/image-capture/Crisp-Packet-View-2@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Crisp-Packet-Front-Up.jpg"
                    srcSet="/images/solutions/image-capture/Crisp-Packet-Front-Up.jpg, /images/solutions/image-capture/Crisp-Packet-Front-Up@1.5x.jpg 1.5x, /images/solutions/image-capture/Crisp-Packet-Front-Up@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Crisp-Packet-View-3.jpg"
                    srcSet="/images/solutions/image-capture/Crisp-Packet-View-3.jpg, /images/solutions/image-capture/Crisp-Packet-View-3@1.5x.jpg 1.5x, /images/solutions/image-capture/Crisp-Packet-View-3@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4 mb-5 d-lg-none text-center">
                  <img
                    src="/images/solutions/image-capture/Box-Front-Up-Mob.jpg"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Box-View-2.jpg"
                    srcSet="/images/solutions/image-capture/Box-View-2.jpg, /images/solutions/image-capture/Box-View-2@1.5x.jpg 1.5x, /images/solutions/image-capture/Box-View-2@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Box-Front-Up.jpg"
                    srcSet="/images/solutions/image-capture/Box-Front-Up.jpg, /images/solutions/image-capture/Box-Front-Up@1.5x.jpg 1.5x, /images/solutions/image-capture/Box-Front-Up@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-4 mb-5 d-none d-lg-block text-center">
                  <img
                    src="/images/solutions/image-capture/Box-View-3.jpg"
                    srcSet="/images/solutions/image-capture/Box-View-3.jpg, /images/solutions/image-capture/Box-View-3@1.5x.jpg 1.5x, /images/solutions/image-capture/Box-View-3@2x.jpg 2x"
                    className="c-postStreamer__image img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="mb-5 col-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h2 className="mb-4">{pageData.services[0].title}</h2>
                  <div className="c-postStreamer__text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageData.services[0].text,
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-5 text-center">
                  <Img
                    fluid={pageData.services[0].image.childImageSharp.fluid}
                    alt={pageData.services[0].title}
                    className="c-postStreamer__image img-fluid"
                  />
                </div>
              </div>

              <div className="row">
                <div className="mb-5 mt-5 col-12 col-md-6">
                  <h2 className="mb-4">{pageData.services[1].title}</h2>
                  <div className="c-postStreamer__text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageData.services[1].text,
                      }}
                    />
                  </div>
                </div>
                <div className="mb-5 mt-5 col-12 col-md-6">
                  <h2 className="mb-4">{pageData.services[2].title}</h2>
                  <div className="c-postStreamer__text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageData.services[2].text,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="mb-5 col-12 col-md-6 order-md-2 d-flex flex-column justify-content-center">
                  <h2 className="mb-4">{pageData.services[3].title}</h2>
                  <div className="c-postStreamer__text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageData.services[3].text,
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-5 text-center">
                  <Img
                    fluid={pageData.services[3].image.childImageSharp.fluid}
                    alt={pageData.services[3].title}
                    className="c-postStreamer__image img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsOne.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="c-section c-section--forPricing">
        <div className="c-section__outer container">
          <div className="c-section__tag">Pricing</div>
          <div className="c-section__inner pb-3">
            <div className="c-priceCards mb-5">
              {pageData.pricing.cards.map((priceCard) => (
                <div
                  key={`image_capture_${priceCard.type}`}
                  className="c-priceCard c-priceCard--large"
                >
                  <header className="c-priceCardHeader">
                    <span className="c-priceCard__subType">
                      {priceCard.type}
                    </span>
                    <span className="c-priceCard__divider"></span>
                    <span className="c-priceCard__price">
                      {priceCard.monthly}
                    </span>
                    <span className="c-priceCard__period">Per product</span>
                  </header>

                  <ul className="c-priceCard__features">
                    {priceCard.has.map((includes) => (
                      <li
                        key={`includes_${includes}`}
                        className="c-priceCard__feature c-priceCard__feature--has"
                      >
                        <img src="/icons/tick.svg" className="mr-2" />
                        {includes}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-3 mb-5 text-center">
                <h2>Large Volume?</h2>
                <p>
                  Please{' '}
                  <Link to="/contact-us" className="c-link">
                    contact us
                  </Link>{' '}
                  to discuss our ‘Photography as a service’ offer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default ImageCapturePage
